import React from 'react';
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import { useAppConfig } from '../../config';
import Layout from '../../components/layout'
import Seo from '../../components/Seo';
import StructuredData from '../../components/structured-data'
import SfForm from '../../components/salesforce/Form';
import SfRecaptcha from '../../components/salesforce/Recaptcha';

function AbusePage({ data }) {
  const { wpPage } = data

  const {sfForms} = useAppConfig();
  const {servlet, orgId, recordTypes} = sfForms;
  const recordType = recordTypes.abuse;

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="py-10 lg:py-20 relative">
        <div className="container relative z-30 text-white">
          <h1 className="text-white mb-2 text-3xl lg:text-4xl">
            Abuse Report Form
          </h1>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundColor: `#2e3c4b`,
          }}
        ></div>
      </div>

      <div className="py-10 container">
        <p className="mb-4">
          Please use this form to report unauthorized activity that you believe
          is occurring on our network that you believe violates Liquid
          Web&apos;s Acceptable Use Policy. We take abuse of our network very
          seriously and review each complaint submitted via this form.
        </p>
        <p className="mb-4">
          If your complaint or concern is of a different matter (e.g. copyright
          infringement, subpoena, subject access request), please do not use
          this form. Instead, we encourage you to review Liquid Web&apos;s
          policies found{' '}
          <Link to="/about-us/policies/" className="underline">
            here
          </Link>{' '}
          for the appropriate contact information.
        </p>
      </div>

      <div className="py-10" style={{ backgroundColor: `#f4f4f4` }}>
        <div className="container">
          <SfForm
            action={servlet}
            method="POST"
          >
            <input type="hidden" name="orgid" value={orgId} />
            <input
              type="hidden"
              name="retURL"
              value="https://www.liquidweb.com/form-success-abuse"
            />
            <input
              type="hidden"
              name="RecordTypeId"
              id="RecordTypeId"
              value={recordType}
            />
            <label htmlFor="name" className="block w-full mb-5">
              <span className="block font-semibold">
                Contact Name<span className="text-red-600">*</span>
              </span>
              <input
                id="name"
                type="text"
                aria-label="name"
                name="name"
                required={true}
                maxLength="80"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="email" className="block w-full mb-5">
              <span className="block font-semibold">
                Email<span className="text-red-600">*</span>
              </span>
              <input
                id="email"
                type="email"
                aria-label="email"
                name="email"
                required={true}
                maxLength="80"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="00N0c00000B9N9q" className="block w-full mb-5">
              <span className="block font-semibold">
                Abuse Type:<span className="text-red-600">*</span>
              </span>
              <select
                id="00N0c00000B9N9q"
                name="00N0c00000B9N9q"
                title="Type of abuse"
                required={true}
                className="form-input"
              >
                <option value="">--None--</option>
                <option value="Spam">Spam</option>
                <option value="Phishing">Phishing</option>
                <option value="Legal">Legal</option>
                <option value="Malicious Activity">Malicious Activity</option>
              </select>
            </label>
            <label htmlFor="00N3a00000Cm1cu" className="block w-full mb-5">
              <span className="block font-semibold">
                What URLs are generating the abuse?
                <span className="text-red-600">*</span>
              </span>
              <input
                id="00N3a00000Cm1cu"
                type="text"
                aria-label="00N3a00000Cm1cu"
                name="00N3a00000Cm1cu"
                required={true}
                maxLength="200"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="00N3a00000Cm1d4" className="block w-full mb-5">
              <span className="block font-semibold">
                What IP address is the abuse coming from?
                <span className="text-red-600">*</span>
              </span>
              <input
                id="00N3a00000Cm1d4"
                type="text"
                aria-label="00N3a00000Cm1d4"
                name="00N3a00000Cm1d4"
                required={true}
                maxLength="200"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="00N3a00000Cm1cz" className="block w-full mb-5">
              <span className="block font-semibold">
                Date:
                <span className="text-red-600">*</span>
              </span>
              <input
                id="00N3a00000Cm1cz"
                type="date"
                aria-label="00N3a00000Cm1cz"
                name="00N3a00000Cm1cz"
                required={true}
                size="12"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="description" className="block w-full mb-5">
              <span className="block font-semibold">
                Additional Comments/Logs
              </span>
              <textarea
                id="description"
                aria-label="description"
                name="description"
                className="form-input w-full"
              />
            </label>
            <input type="hidden" id="external" name="external" value="1" />
            <div className="w-1/2">
              <SfRecaptcha Warning={(
                <div className="text-red-600 pt-5 font-semibold">Please verify you are not a robot!</div>
              )} />
              <button
                className="btn btn-primary p-3 w-full font-semibold mt-8"
                type="submit"
              >
                Submit
              </button>
            </div>
          </SfForm>
        </div>
      </div>
    </Layout>
  )
}

AbusePage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default AbusePage

export const query = graphql`
  {
    wpPage(slug: { eq: "abuse" }) {
      title
      date
      modified
      uri
      chatDepartment
      seo {
        ...postSeoFields
      }
    }
  }
`
